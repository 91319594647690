.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Logo-style {
  width: 85%;
  height: 10%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.List-style {
  list-style-type:none;
  display:table; 
  margin:0 auto;
}
.textAlign {
  text-align: center;
}
.formInput {
   border-radius: 25px;
   border: 2px solid #D5D5D5;
   width: 100%;
   height: 45px; 
   padding-left: 20px; 
   font-family: Arial;
   font-size: 16px;
   line-height: 14px;
   color: #D5D5D5;
}
.authButton {
  height: 45px;
  width: 100%;
  border-radius: 25px;
  border: 2px solid ;
  background-color: #96C63C ;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.general-card {
  margin-top: 10px;
}

.textStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
  text-align: center;
}

.fpText {
  color: #2481C1;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.alertText {
  color: #ff0000;
}

.productText {
  color: #FFFFFF;
  text-align: center;
  background-color: #96C63C;
}

.containerColor {
  background-color: #ECECEC;
  min-height: 800px;
}

.cardText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #0C5692;
}

.cardBorder {
  border-bottom-style: solid;
  border-bottom-color: #C8C8C8;
}

.profileIcon {
  height:25px;
  width:25px;
  padding-right: 5px;
  -webkit-align-items: center;
          align-items: center;
}

.modalBGColor {
  background-color: #FFFFFF;
  opacity: 1;
}

.modal-content  {
  border-radius: 4px; 
  background: #FFFFFF;
}
.noBorder {
  border-bottom: 0px !important;
  border:0px !important;
}

.labelText {
  color: #2993D1;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.rowBorder {
  border-bottom: 1px solid #D5D5D5;
}

.menuHeight {
  line-height: 3.5;
  padding-right: 15px !important;
}

.closeButton {
  -webkit-align-items: center;
          align-items: center;
}

.topBarCenter {
  text-align: center;
}
.topBarLeft {
  text-align: left;
}

.topBarRight {
  text-align: right;
}

.opportunityBanner{
  color: #FFFFFF;
  text-align: right;
  background-color: #2699FB;
}

@media screen and ( max-width: 767px ) {
 /* When the viewport is 768px or less, 
    hide #sidebar */
    .topBarWidth {
      padding-top: 3px;
      padding-left: 5px;
      height: 43px;
    }

  .topBarLogo{
    max-width: 100%;
    max-height: 65%;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 0px;
  }

  .topBarTag{
    display: block;
  }

  .sideBarTag{
    display: none;
  }
}

@media screen and ( min-width: 769px ) and  ( max-width: 1024px ) {
 /* When the viewport is 768px or less, 
    hide #sidebar */
    .topBarWidth {
      padding-left: 5px;
      padding-top: 3px;
      height: 48px;
      overflow: hidden;
    }

  .topBarLogo{
    max-width: 100%;
    max-height: 65%;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 0px;
  }

  .grid-item.main {
    display: none !important;
  }
}

.shareIcon {
  height: 75%;
  width: 75%;
}

.competencyIcon {
  height: 50%;
  width: 50%;
}

.dashboardBannerSpace {
  padding-bottom: 20px;
}

.desktopMessage {
  display: none;
  }

@media screen and ( min-width: 1023px ) {
 /* When the viewport is 768px or less, 
    hide #sidebar - Changes for topbar in Lapview has to be done here height,background  */
    .topBarWidth {
      padding-left: 5px;
      padding-top: 3px;
      height: 50px;
      background: #2481c1;
      position: fixed;
      width: 100%;
    }

  .topBarLogo{
    display: none;
  }

  .shareIcon{
    height: 100%;
    width: 35%;
  }

  .shareText {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
  }
  .shareNone {
    display: none
  }

  .maincontent{
    margin-top:50px;
    overflow: auto;
    height: calc(100vh - 55px);
  }

  .topBarCenter{
    text-align: center;
  }

  .hideBurgerMenu{
    display: none;
   }

  .hideNavBar {
    display: none;
  }

  .dashboardBannerSpace {
    padding-bottom: 0px;
  }

  .competencyIcon {
    height: 40%;
    width: 25%;
 }

 .desktopMessage {
  display: block;
  text-align: center;
  background: lightgrey;
  }

  .grid-item.main, .mobile-post-message {
    display: none !important;
  }

  .topBarTag{
    display: none;
  }

  .sideBarTag{
    display: block;
    padding-left: 1rem;
  }
}

@media screen and ( max-width: 1025px ) {
 /* When the viewport is 768px or less, 
    hide #sidebar */
    .visibleSideBar {
      display: none;
    }

    .containerColor {
      -webkit-flex: 100% 1;
              flex: 100% 1;
      max-width: 100%;
    }
}


@media screen and ( max-width: 1023px ) {
 /* When the viewport is 768px or less, 
    hide #sidebar */
    .sidebarstyle, .desktop-post-message {
        display: none;
    }
}

@media screen and ( max-width: 768px ) {
  /*When the viewport is 768px or less, 
    hide #sidebar */
  .containerColor {
      -webkit-flex: 100% 1;
              flex: 100% 1;
      max-width: 100%;
  }
  .swiper-container {
    display: none !important; 
  }
  .dropdown-toggle::after {
    width: 0em;
    height: 0em;
    border: none;
    border-top: 0;
    border-left: 0;
    -webkit-transform: none;
    transform: none;
  }
  .webinar-carousel, .learning-resources-carousel, .recommendations-carousel, .swiper-container .featured-carousel, .certified-carousel {
    display: none !important;
  }
}

.modalCenter{
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.headerText{
  color: #FFFFFF;
  text-align: center;
  background-color: #2699FB;
}

.modalAlign{
  padding-left: 0px !important;

}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.dropdownWidth{
  width: 85%;
  font-size: 10px;
  background-color: #FFFFFF;
  border-radius: 0px;
}

.resumeText{
  font-size: 14px;
  color:#0C5692;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 300;
}

.body{
  line-height: 1;
}

.pageHeader{
  font-size: 17px;
  color:#FFFFFF;
  font-family: Roboto Condensed,sans-serif;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
}

.inputFile{
  width: 100%;
}

.infoSave{
  width: 40%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 35px;
}

.scrollBar{
  height: 250px;
  overflow-y: scroll;
}

.inputFile{
  width: 100%;
}

.nav{
  /*background: black*/
}

#main-nav{
 background:#1f2f46;
 color: white;
 position: fixed;
 top:45px;
 left: 0;
 height: calc(100vh - 45px);
 overflow-y: auto;
 width:16%;
 -webkit-overflow-scrolling: touch;
 margin-top:5px;
 font-size: 12px;
}

@media screen and ( min-width: 1025px ) {
 /* When the viewport is 768px or less, 
    hide #sidebar */
    .app__content{
      padding-top:20px;
    }
}

.react-icons {
  vertical-align: middle;
  margin:10px;
}

.nav-text {
  color: white;
  padding-top: 8px;
  padding-left: 15px;
}

.homeIcon {
  height:50px;
  width:50px;
  -webkit-align-items: center;
          align-items: center;
  position:fixed;
  bottom:80px;
  right:15px;
}

.formGroup {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0rem;
}

.formRow {
  padding-left: 20px;
  padding-right: 20px;
}

.menuText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0C5692;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
  -webkit-filter: invert(100%);
          filter: invert(100%);
}


.label{
  padding: 10px;
  background: #2993D1;
  color: #FFFFFF;
  border-radius: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 5px;
  cursor: pointer;
}

.label [type="file"] {
  display: none;
}

.typography_link {
 color: #0000EE;
}

.caption {
  font-size: 12px;
}

@media only screen and (min-width: 768px) and  ( max-width: 800px ) {
  /* For general iPad layouts */
  .topBarWidth {
      padding-left: 5px;
      padding-top: 3px;
      height: 60px;
      overflow: hidden;
    }
}

.updateButton {
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-primary:disabled{
  background-color: grey;
  border-color: grey;
}

.divider{
  border-bottom:1px solid black;
}

/*Redesign  Works*/

/*.form-control::-webkit-input-placeholder { color: #000000; }  /* WebKit, Blink, Edge */
/*.form-control:-moz-placeholder { color: #000000; }  /* Mozilla Firefox 4 to 18 */*/
/*.form-control::-moz-placeholder { color: #000000; }  /* Mozilla Firefox 19+ */*/
/*.form-control:-ms-input-placeholder { color: #000000; }  /* Internet Explorer 10-11 */*/
/*.form-control::-ms-input-placeholder { color: #000000; }  /* Microsoft Edge */*/

.heading {
  color: #16355C;
  font-weight: bold;
  text-align: center;
}

.formInputRedesign {
  border-radius: 25px;
  border: 0.5px solid #16355C;
  font-size: 16px;
  width: 100%;
  height: 40px; 
  text-align: center; 
  color: #000000;
  font-weight: 400;
}

.authButtonRedesign {
  height: 100%;
  width: 50%;
  border-radius: 25px;
  border: 0.5px #707070;
  background-color: #96C63C ;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #000000;
}

.alertTextRedesign {
  color: #E53A34;
  font-weight: 600;
  text-align:  center;
}

.successTextRedesign {
  color: #21CB70;
  font-weight: 600;
  text-align:  center;
}

/*Theme Based Changes*/
#theme-settings .theme-settings-open-btn{
  display: none
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233db0e4' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233db0e4' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-size: 100%, 100%;
  background-color: white;
  -webkit-filter: invert(0%);
          filter: invert(0%);
}

.lnr-chevron-right:before {  
  content: ""; 
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233db0e4' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}


.lnr-chevron-left:before {
  content: ""; 
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233db0e4' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.swiper-button-prev.custom-icon,
.swiper-button-next.custom-icon {
  background-size: 100%, 100%;
  background-color: white;
  -webkit-filter: invert(0%);
          filter: invert(0%);    
  top: 40%;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  right: 0;    
  transition: all .25s ease-in;
  opacity: .4;
  position: absolute;
  z-index: 2;
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
}

.circle {  
  -webkit-align-items: center;  
          align-items: center;
  bottom: 15vh;
  right: 2vh;
  z-index: 2;
  position: fixed;
}

/*Cursor for Get Certified Items*/
.cursorPointer {
  cursor: pointer;
}

.rewardPointsHeader {
  background-repeat: no-repeat;
  background-image: url("https://mycentacertificationsubmissions.nyc3.digitaloceanspaces.com/rp_halfbanner.png");
  background-size: 100% 100%;
}

.cardBottom {
  margin-bottom: 2%;
}

.placesAutoComplete {
  font-size: .894rem;
  border-radius: .25rem;
  border: 1px solid rgba(24,28,33,0.1);
}
@media only screen and (max-width: 600px) {
  .topSpacing {
    padding-top: 1%;
  }
}

.webinar-carousel li.slide,
.learning-resources-carousel li.slide,
.recommendations-carousel li.slide,
.certified-carousel li.slide,
.featured-carousel li.slide {
  cursor: pointer;
  text-align: left;
  padding-left: 2%;
  padding-right: 2%;
}

.home-layout .layout-navbar {
  box-shadow: none;
}

.hp-card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 26px;
}

.hp-card-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.hp-card-attributes {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #666666;
}

.hp-card-data {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: #000000;
}

.hp-card-register {
  font-family: Poppins;
  background: #1E70CD;
  border-radius: 3px;
  font-weight: bold;
}

.hp-form-cancel {
  font-family: Poppins;
  background: #ffffff;
  border-radius: 5px;
  color: #1E70CD;
  border: 2px solid #1E70CD;
  font-weight: bold;
}

.hp-form-register {
  font-family: Poppins;
  background: #1E70CD;
  border-radius: 5px;
  color: #ffffff;
  border: 2px solid #1E70CD;
  font-weight: bold;
}

.hp-form-register:hover {
  font-family: Poppins;
  background: #1E70CD;
  border-radius: 5px;
  color: #ffffff;
  border: 2px solid #1E70CD;
  font-weight: bold;
}

.floating .floating-label  {
  position:absolute;
  top:2px;
  left: 0;
  padding-left: 20px;
  color: #999;
  transition:.5s;
  pointer-events:none;
  -webkit-justify-content: left;
          justify-content: left
}

.floating .floating-label.active {
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
  transition:.5s;
  color: black;
  font-weight:bold;
}

.layout-collapsed .home-layout .sidenav.sidenav-vertical.layout-sidenav.bg-sidenav-theme {
  display: none !important;
}

.home-layout {
  background-color: #2481c1;
  font-family: Poppins;
  color: #FFFFFF;
  font-size: 1rem;
}

.dashboard .dashboard-title {
  font-size: 1.125rem;
  margin-top: 20px;
  font-weight: 700;
}

.hp-interested-header {
  font-family: Poppins;
  font-weight: bold;
  text-transform: uppercase;
  color: #000000;
  font-style: normal;
  font-size: 16px;
  padding-top: 3vh;
  padding-left: 2vh;
}

.btn.recommend-webinar-register {
  font-family: Poppins;
  font-weight: 500;
  font-size: 1rem;
  color: #26B4FF;
}

.hp-share-btn {
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
}

.sidenav-inner {
  font-size: 0.875rem;
}

.webinar-series button.tabs.selected {
  background-color: #FFFFFF !important;
  color: #2481c1 !important;
  font-weight: 600;
}

.webinar-series button.tabs {
  background-color: #3382DB;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.home-layout .blog-card {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  border-radius: 10px;
}

.home-layout .blog-category-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #87BF24;
  text-align: left;
}

.carousel-root .slide.selected {
  opacity: 1;
}

.carousel-root .slide {
  opacity: 0.6;
}

.dashboard .type-blog {
  font-family: Poppins;
  font-size: 1rem;
  line-height: 26px;
}

.webinar-series .acknowledge.btn,
.webinar-series .watch.btn {
  font-family: Poppins;
  font-weight: 500;
  font-size: 1rem;
  color: #26B4FF;
}

.webinar-series .acknowledge.btn:disabled {
  color: #CCCCCC;
}

.img-top-curved-border {
  border-radius: 15px 15px 0px 0px;
}

.learning-resources-carousel .carousel.carousel-slider .control-arrow,
.recommendations-carousel .carousel.carousel-slider .control-arrow,
.webinar-carousel .carousel.carousel-slider .control-arrow,
.featured-carousel .carousel.carousel-slider .control-arrow,
.certified-carousel .carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.grid-item {
    padding: 3.5em 1em;
  }

  .main {
    grid-area: main;
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .items {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    will-change: transform;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
  }

  .item {
    display: inline-block;
    max-width: 80%;
    margin: 0 7px;
    vertical-align: top;
    white-space: pre-wrap;
  }

  .items::-webkit-scrollbar {
    display: none;
  }

  .items {
    scrollbar-width: none;
  }

  .float-wrapper {
    display: inline-block;
    padding: 5px 0 10px 10px;
    background: #FFFFFF;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .float-wrapper.last, .multi-block {
    border: 1px solid #CCCCCC;
  }

  .float-wrapper.invalid, .multi-block.invalid {
    border: 1px solid red;
  }

  .multi-block {
    border-radius: 5px;
  }

  .float-wrapper.invalid .float-label {
    color: red;
  }

  .float-input {
    outline: none;
    border: none;
    display:block;
    line-height: 1.2em;
    font-size: 1rem;
    color: #000000;
    width: 100%;
    background-color: #FFFFFF;
  }

  .float-input:disabled {
    background-color: #EEEEEE;
  }

  .float-label {
    font-family: Roboto;
    display: block;
    color: #8b8b8b;
    font-size: .9rem;
  }

  .flex-wrapper {
    display: -webkit-flex;
    display: flex;
  }

  .home-layout-font {
    font-family: Poppins;
    font-size: 1rem;
  }

  .home-layout-color {
    background-color: #2481c1;
    color: #FFFFFF;
  }

  .discount-btn {
    background-color: #96C63C;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 15px;
  }

  .discount-btn:disabled {
    background-color:gray;
    border-color: gray;
  }

  .profile-section-heading-wrapper {
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    background-color: #E9F6FE;
    color: #000000;
  }

  .post-input-wrapper {
    padding: 3vh 8vw;
  }

  .tag-line {
    color: #0c5692;
    font-family: 'Poppins';
  }
  .righticon {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 3.35em;
    border-radius: 0px 19px 0px 0px;
    border-right-color: #87bf24;
    border-top-color: #87bf24;
  }

  .righticon .fas.fa-check {     
    position: absolute;
    left: 7px;
    bottom: 3px;
    color: #fff;
  }

  .custom-dialog {
    width: 100% !important;
    top: 27%;
    left: 8%;
  }

  .order-modal-header {
    font-family: 'Poppins';
    font-weight: bold;
    display: block;
    border-bottom: none;
    padding-bottom: 0;
  }

/*  .order-modal-content {
    border-radius: 0px;
  }*/

  .master-class-modal {
    position: fixed;       
    bottom: 0;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .affiliate-program-modal {
      position: fixed;       
      bottom: 0;
      -webkit-transform: translate(-50%);
              transform: translate(-50%);
      -webkit-align-items: center;
              align-items: center;
      width: 100%;
      border-radius: 0px;
      margin: 0px;
      max-width: 100%;
    }
  }

  .iframe-ew-scl-admin {
    width: 100%;
    height: 100%;
    border: 1px solid black;
  }

  .required:after {
    color: #d00;
    content: "*";
    padding: 2px;
    font-size: 10px;
  }

  .mandatory:after {
    color: red;
    content: " *";
  }

  /*profile Image */
  .profile-avatar-img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    position: relative;
    border-radius: 50%;
    margin-left: 15px;
  }

  @media only screen and (min-width: 376px) and (max-width: 426px) {
    .profile-avatar-img{
      position: absolute;
      top: 0.5vh;
      right: 6vw;
    }
  }

  @media only screen and (max-width: 376px) {
    .profile-avatar-img{
      position: absolute;
      top: 0.5vh;
      right: 6vw;
    }
  }

  .addcart_btn{
    position: relative;
    padding: 6px 9px 6px 9px;
    background-color: #e9f6fe;
  }

  .addcart_btn:hover
  {
    background-color: #26b4ff;
  }

  .removecart_btn{
    position: relative;
    padding: 6px 9px 6px 9px;
    background-color: #f6eaea;
  }

  .cartbtn_txt{
    padding-top: 12px;
    position: absolute;
    color: #666666;
  }

  .Affiliate_popup_img{
    width: 30%;
    height: 30vh;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom:20px;
  }

  .navbar{
    width: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    z-index: unset;
  }
  
  .scrolled{
    width: 84%;
    top: 0;
    position: fixed;
    background-color: #2481c1;
    z-index: 1;
  }

  @media screen and (max-width:768px) {
    .navbar-title ,.navbar-savecart{
      font-size: 17px;
    }
    .scrolled{
      width: 100%;
    }
    .navbar-cart {
      padding-right: 40px;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }

  .registrations-top-container {
    background-color: #E9F6FE;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* padding: 15px 5px; */
    display: -webkit-flex;
    display: flex;
  }

  .registrations-bottom-container {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    display: -webkit-flex;
    display: flex;
    /* background-color: #FFFFFF; */
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    /* padding: 15px 5px; */
  }

  .display-flex, .registrations-bottom-container,
  .past-registrations-bottom-container,
  .upcoming-registrations-bottom-container {
    display: -webkit-flex;
    display: flex;
    border-top: 1px solid #CCCCCC;
  }


  .registrations-bottom-container {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .past-registrations-bottom-container,  .upcoming-registrations-bottom-container {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  
  .default-font {
    font-family: 'poppins';
  }

  .signin-top-container {
    height: 40vh;
    background-color: #E9F6FE;
  }

  .forget-top-container {
    height: 40vh;
    background-color: #E9F6FE;
  }

  .signup-top-container {
    height: 29vh;
    background-color: #E9F6FE;
  }

  .signin-top-txt {
    font-family: 'poppins';
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 13px;
  }

  @media screen and (max-width: 425px) {
    .signin-top-txt {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 375px) {
    .signin-top-txt {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 320px) {
    .signin-top-txt {
      font-size: 9px;
    }
    .signup-country-code {
      font-size: 11px;
    }
    .navbar-cart {
      padding-right: 20px;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  
  .heading-txt {
    font-family: 'Poppins';
    font-weight: bold;
  }
  
  .signin-btn {
    font-family: 'poppins';
    font-weight: bold;
    padding: 1em;
    background-color: #1e70cd;
  }
  
  .forget-txt {
    font-family: 'poppins';
    font-size: 13px;
    color: #666666;
  }
  
  .forget-btn {
    font-family: 'poppins';
    color: #1e70cd;
  }

  .privacy-policy-btn {
    font-family: 'poppins';
    color: #1e70cd;
    cursor: pointer;
  }
  
  
  .common-card-body {
    background-color: #E9F6FE;
    border-radius: 15px;
  }

  .common-card-footer {
    background-color: #FFFFFF;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    padding: 0;
  }

  @media screen and ( min-width: 633px ) {
    .xs-sm-tagline {
      display: none;
    }
    .webinar-detail-img {
      height: 150px !important;
      object-fit: contain !important;
    }
  }

  @media screen and ( max-width: 632px ) {
    .md-lg-tagline {
      display: none;
    }
  }

  .md-lg-swiper-carousel {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #FFFFFF !important;
    color: #000000;
    font-family: 'Poppins';
    font-size: 16px !important;
    cursor: pointer;
  }

  .post-webinar-swiper-carousel {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    background-color: #2481c1 !important;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 14px !important;
    cursor: pointer;
  }

  .detailed-webinar-swiper-carousel {
    border-radius: 20px !important;
    background-color: #FFFFFF !important;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 14px !important;
    cursor: pointer;
    padding: 3vh;
  }

  .detailed-webinar-swiper-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .add-opportunity{
    margin-left: 20px;
  }

  .inline-display{
    display: inline;
  }

  table.table-border, .table-border td, .table-border th {
    border: 1px solid black;
  }

  .table-border td, .table-border th {
    text-align: center;
  }

  table.table-border {
    border-collapse: collapse;
    width: 100%;
  }

  .suggestion-item-active {
    background-color: #fafafa;
    cursor: pointer;
    color: #000000;
  }

  .suggestion-item {
    background-color: #ffffff;
    cursor: pointer;
    color: #000000;
  }

  .red-color {
    color: red;
  }
  
  .pd-hours-text {
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;
    color: #000000;
  }

  .profile-section {
    position: relative;
    top: 0.5vh;
  }
  .profile-img-border {
    border: 3px solid #FFD700;
  }
  .profile-img-box {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px){
    .profile-img-border {
      border: 3px solid #FFD700;
      width: 50px;
      height: 50px;
      top: 0.1vh;
      right: 5vw;
    }
    .pd-hours-text {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
    .profile-section {
      position: relative;
      top: 1.5vh;
    }

    .profile-img-box {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
  }

  .categories-section-border {
    border: 1px solid #CCCCCC;
    border-radius: 10px;
  }

  .categories-section-red-border {
    border: 1px solid red;
    border-radius: 10px;
  }

  .red-color-border {
    border: 1px solid red;
  }

  /* School admin styles */
  .navbar-cart {
    margin-left: 1rem ;
    margin-right: 1rem ;
  }

  .navbar-cart-button {
    display: block;
    height: 40px;
    width: 40px;
    color: #2481c1;
    cursor: pointer;
    position: relative;
    left: 11px;   
    top: 5px;
    font-size: 26px;
  }

  .modal-close-btn {
    font-size: 20px;
    font-weight: bold;
    color: #0062cc;
  }

  .modal-close-btn:hover {
    color: #2481c1;
  }

  .learning-record button.tabs.selected {
  background-color: #FFFFFF !important;
  color: #2481c1 !important;
  font-weight: 600;
  border-top: 3px solid #2481c1;
  opacity: 1;
}

.learning-record button.tabs {
  background-color: #FFFFFF;
  color: #2481c1;
  font-weight: 600;
  font-size: 1rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  opacity: 0.7;
}

/*
 Versions
 2022.08.29 PK - Added Looking for opprtunity component.

 */
.authentication-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  min-height: 100vh;
  width: 100%; }
  .authentication-wrapper .authentication-inner {
    width: 100%; }
  .authentication-wrapper.authentication-1, .authentication-wrapper.authentication-2, .authentication-wrapper.authentication-4 {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .authentication-wrapper.authentication-1 .authentication-inner {
    max-width: 300px; }
  .authentication-wrapper.authentication-2 .authentication-inner {
    max-width: 380px; }
  .authentication-wrapper.authentication-3 {
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: stretch;
            justify-content: stretch; }
    .authentication-wrapper.authentication-3 .authentication-inner {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: stretch;
              align-items: stretch;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
      -webkit-justify-content: stretch;
              justify-content: stretch; }
  .authentication-wrapper.authentication-4 .authentication-inner {
    max-width: 800px; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .authentication-wrapper:after {
    content: '';
    display: block;
    -webkit-flex: 0 0;
            flex: 0 0;
    min-height: inherit;
    width: 0;
    font-size: 0; } }

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto; }

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute; }

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent; }

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6; }

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9; }

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute; }

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute; }

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px; }

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }

.ps {
  position: relative; }

.ps__rail-x,
.ps__rail-y,
.ps__thumb-x,
.ps__thumb-y {
  border-radius: 10rem; }

.ps__rail-x {
  height: 0.25rem; }

.ps__rail-y {
  width: 0.25rem; }

.ps__thumb-x {
  bottom: 0;
  height: 0.25rem; }

.ps__thumb-y {
  right: 0;
  width: 0.25rem; }

.ps__rail-x:hover,
.ps__rail-x:focus,
.ps__rail-x.ps--clicking,
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking > .ps__thumb-x {
  height: 0.375rem; }

.ps__rail-y:hover,
.ps__rail-y:focus,
.ps__rail-y.ps--clicking,
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking > .ps__thumb-y {
  width: 0.375rem; }

.default-style .ps__rail-x:hover,
.default-style .ps__rail-y:hover,
.default-style .ps__rail-x:focus,
.default-style .ps__rail-y:focus,
.default-style .ps__rail-x.ps--clicking,
.default-style .ps__rail-y.ps--clicking {
  background-color: rgba(24, 28, 33, 0.1); }

.default-style .ps__thumb-x,
.default-style .ps__thumb-y {
  background-color: rgba(24, 28, 33, 0.3); }

.default-style .ps__rail-x:hover > .ps__thumb-x,
.default-style .ps__rail-y:hover > .ps__thumb-y,
.default-style .ps__rail-x:focus > .ps__thumb-x,
.default-style .ps__rail-y:focus > .ps__thumb-y,
.default-style .ps__rail-x.ps--clicking > .ps__thumb-x,
.default-style .ps__rail-y.ps--clicking > .ps__thumb-y {
  background-color: rgba(24, 28, 33, 0.6); }

.default-style .ps-inverted .ps__rail-x:hover,
.default-style .ps-inverted .ps__rail-y:hover,
.default-style .ps-inverted .ps__rail-x:focus,
.default-style .ps-inverted .ps__rail-y:focus,
.default-style .ps-inverted .ps__rail-x.ps--clicking,
.default-style .ps-inverted .ps__rail-y.ps--clicking {
  background-color: rgba(255, 255, 255, 0.5); }

.default-style .ps-inverted .ps__thumb-x,
.default-style .ps-inverted .ps__thumb-y {
  background-color: rgba(255, 255, 255, 0.7); }

.default-style .ps-inverted .ps__rail-x:hover > .ps__thumb-x,
.default-style .ps-inverted .ps__rail-y:hover > .ps__thumb-y,
.default-style .ps-inverted .ps__rail-x:focus > .ps__thumb-x,
.default-style .ps-inverted .ps__rail-y:focus > .ps__thumb-y,
.default-style .ps-inverted .ps__rail-x.ps--clicking > .ps__thumb-x,
.default-style .ps-inverted .ps__rail-y.ps--clicking > .ps__thumb-y {
  background-color: #fff; }

.material-style .ps__rail-x:hover,
.material-style .ps__rail-y:hover,
.material-style .ps__rail-x:focus,
.material-style .ps__rail-y:focus,
.material-style .ps__rail-x.ps--clicking,
.material-style .ps__rail-y.ps--clicking {
  background-color: rgba(24, 28, 33, 0.1); }

.material-style .ps__thumb-x,
.material-style .ps__thumb-y {
  background-color: rgba(24, 28, 33, 0.3); }

.material-style .ps__rail-x:hover > .ps__thumb-x,
.material-style .ps__rail-y:hover > .ps__thumb-y,
.material-style .ps__rail-x:focus > .ps__thumb-x,
.material-style .ps__rail-y:focus > .ps__thumb-y,
.material-style .ps__rail-x.ps--clicking > .ps__thumb-x,
.material-style .ps__rail-y.ps--clicking > .ps__thumb-y {
  background-color: rgba(24, 28, 33, 0.6); }

.material-style .ps-inverted .ps__rail-x:hover,
.material-style .ps-inverted .ps__rail-y:hover,
.material-style .ps-inverted .ps__rail-x:focus,
.material-style .ps-inverted .ps__rail-y:focus,
.material-style .ps-inverted .ps__rail-x.ps--clicking,
.material-style .ps-inverted .ps__rail-y.ps--clicking {
  background-color: rgba(255, 255, 255, 0.5); }

.material-style .ps-inverted .ps__thumb-x,
.material-style .ps-inverted .ps__thumb-y {
  background-color: rgba(255, 255, 255, 0.7); }

.material-style .ps-inverted .ps__rail-x:hover > .ps__thumb-x,
.material-style .ps-inverted .ps__rail-y:hover > .ps__thumb-y,
.material-style .ps-inverted .ps__rail-x:focus > .ps__thumb-x,
.material-style .ps-inverted .ps__rail-y:focus > .ps__thumb-y,
.material-style .ps-inverted .ps__rail-x.ps--clicking > .ps__thumb-x,
.material-style .ps-inverted .ps__rail-y.ps--clicking > .ps__thumb-y {
  background-color: #fff; }

.NotFound_Container__34QDK {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.NotFound_Status__DMY36 {
  font-size: 10rem;
  font-weight: 400;
  margin: 0;
  line-height: 1; }

.NotFound_Message__2OJoY {
  font-weight: 300;
  font-size: 3.25rem;
  margin: 0;
  line-height: 1; }

@-webkit-keyframes Loader_RouterLoaderAnimation__1ImXa {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes Loader_RouterLoaderAnimation__1ImXa {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Loader_Container__3m80Y {
  position: fixed;
  z-index: 99999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0.25);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation: Loader_RouterLoaderAnimation__1ImXa .2s;
          animation: Loader_RouterLoaderAnimation__1ImXa .2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.Loader_Spinner__3BO6F {
  display: block; }

.Loader_SpinnerEl__2m1_h {
  border-width: 2px !important;
  width: 3rem !important;
  height: 3rem !important; }

.app-loading .Loader_Container__3m80Y {
  display: none; }

.default-style .sidenav .app-brand.demo {
  height: 66px; }

.material-style .sidenav .app-brand.demo {
  height: 70px; }

.app-brand-logo.demo {
  display: -webkit-flex;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.app-brand-logo.demo svg {
  width: 20px;
  height: 11px; }

.app-brand-text.demo {
  font-size: 1.1rem; }

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none; }

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -webkit-flex !important;
  display: flex !important; }

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important; }

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none; } }

/* *****************************************************************************
 * Navbar
 */
.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap; }

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0; }

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: .5rem;
    width: 22rem; }
  .demo-navbar-user .dropdown-menu {
    margin-top: .25rem; } }

/* *****************************************************************************
 * Content
 */
.demo-vertical-spacing > * + * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important; }

.demo-vertical-spacing-sm > * + * {
  margin-top: .9375rem !important;
  margin-bottom: 0 !important; }

.demo-vertical-spacing-lg > * + * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important; }

.demo-inline-spacing > *,
.demo-paragraph-spacing > p > * {
  margin: 0 .375rem .9375rem 0 !important; }

.demo-paragraph-spacing > p {
  margin: 0; }

.demo-blocks > * {
  display: block !important; }

html:not(.material-style) .material-style-only {
  display: none !important; }

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important; }

[dir=rtl] .rtl-only {
  display: block !important; }

/* *****************************************************************************
 * Layouts
 */
.layout-example-block {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.04);
  color: #c7254e; }

.layout-example-block code {
  background: none;
  color: #666; }

.layout-example-block-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 500px; }

.layout-example-block-sidenav {
  width: 24%;
  background: #607d8b; }

.layout-example-block-sidenav code {
  color: #fff; }

.layout-example-block-navbar {
  background: #3ca99e; }

.layout-example-block-navbar code {
  color: #fff; }

.layout-example-block-content {
  background: #9e9e9e; }

.layout-example-block-content code {
  color: #fff; }

.layout-example-block-1 .layout-example-block-content {
  width: 74%; }

.layout-example-block-2 .layout-example-block-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 74%; }

.layout-example-block-2 > .layout-example-block {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto; }

.layout-example-block-2 .layout-example-block-content {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.layout-example-block-1-no-sidenav .layout-example-block-content {
  width: 100%; }

.layout-example-code {
  display: block;
  overflow: auto;
  margin: 0;
  padding: 30px;
  min-width: 0;
  width: 100%;
  background: #fff; }

.layout-example-run {
  display: inline-block;
  margin: 3px;
  padding: 4px 6px;
  border: 1px solid #eee;
  background: transparent;
  white-space: nowrap;
  line-height: 1; }

.layout-example-block-3 .layout-example-block-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.layout-example-block-3 .layout-example-block-inner > .layout-example-block {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto; }

.layout-example-block-3 .layout-example-block-inner .layout-example-block-container-fluid {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.layout-example-block-3 .layout-example-block-sidenav-horizontal {
  background: #607d8b; }

.layout-example-block-3 .layout-example-block-container-fluid {
  background: #fff; }

.layout-example-block-3 .layout-example-block-container-fluid code {
  color: #666; }

.layout-example-block-3 .layout-example-block-footer {
  background: #e6514a; }

